import { createTheme } from "@mui/material/styles";

export default createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#6a27e8",
      dark: "#3f119b",
    },
    accent: {
      main: "#fd4b68",
    },
    secondary: {
      main: "#626972",
      light: "#f4f4fa",
      dark: "#2b2a28",
    },
  },
  typography: {
    fontFamily: ["Nunito Sans"].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "inherit",
        },
      },
    },
  },
});
