import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import {
  loadTranslations,
  syncTranslationWithStore,
  setLocale,
} from "react-redux-i18n";
import { createLogger } from "redux-logger";
import { thunk } from "redux-thunk";
import rootReducer from "./rootReducer";
import translationsObject from "./lang";

const initialState = {};
const enhancers = [];
const middlewares = [thunk];

if (process.env.NODE_ENV === "development" && typeof window !== "undefined") {
  const logger = createLogger();
  middlewares.push(logger);

  const { devToolsExtension } = window;
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middlewares),
  ...enhancers
);

const store = createStore(rootReducer, initialState, composedEnhancers);

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translationsObject));
store.dispatch(setLocale("en"));

export default store;
