import React from "react";
import "./src/global.css";
import theme from "./src/theme";
import { ThemeProvider } from "@mui/material/styles";
import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";
import { get } from "lodash";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import { Provider, useDispatch } from "react-redux";
import store from "./src/state/store";
import { useMount } from "react-use";
import { getUserData } from "./src/state/actions/user";
import { setAuthInit, setAuthLoader } from "./src/state/actions/base";
import { initializeGA, handleGACookies } from "./src/helpers/analytics";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";

const RootComponent = ({ element }) => {
  const dispatch = useDispatch();

  useMount(async () => {
    if (element?.props?.location) return; // prevent from running twice... location is available when running for Head component

    const { tokens } = await fetchAuthSession();

    if (tokens) {
      dispatch(setAuthLoader(true));

      await dispatch(getUserData(tokens));
    }

    dispatch(setAuthInit(true));
    dispatch(setAuthLoader(false));
  });

  return <>{element}</>;
};

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RootComponent element={element} />
      </ThemeProvider>
    </Provider>
  );
};

export const onClientEntry = () => {
  dayjs.extend(utc);
  dayjs.extend(duration);

  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: process.env.GATSBY_AUTH_USER_POOL_ID,
        userPoolClientId: process.env.GATSBY_AUTH_USER_POOL_CLIENT_ID,
        loginWith: {
          oauth: {
            domain: process.env.GATSBY_AUTH_COGNITO_DOMAIN,
            scopes: ["openid", "profile", "email"],
            redirectSignIn: [`${window.location.origin}/user/redirect-signin`],
            redirectSignOut: [
              `${window.location.origin}/user/redirect-signout`,
            ],
            responseType: "code",
            providers: ["GitHub", "Google"],
          },
        },
      },
    },
    API: {
      REST: {
        users: {
          endpoint: process.env.GATSBY_USER_API,
        },
        community: {
          endpoint: process.env.GATSBY_COMMUNITY_API,
        },
        surveys: {
          endpoint: process.env.GATSBY_SURVEY_API,
        },
        dashboards: {
          endpoint: process.env.GATSBY_DASHBOARD_API,
        },
      },
    },
  });

  if (process.env.GATSBY_AUTH_CUSTOM_DOMAIN)
    cognitoUserPoolsTokenProvider.setKeyValueStorage(
      new CookieStorage({
        domain: process.env.GATSBY_AUTH_CUSTOM_DOMAIN,
        path: "/",
        expires: 365,
        secure: true,
      })
    );
};

export const onInitialClientRender = () => {
  let scriptElement = document.createElement("script");

  // Set the attributes based on your configuration
  scriptElement.id = "Cookiebot";
  scriptElement.src = "https://consent.cookiebot.com/uc.js";
  scriptElement.setAttribute("data-cbid", process.env.GATSBY_COOKIEBOT_ID);
  scriptElement.type = "text/javascript";
  scriptElement.async = true;

  // Append the script element to the document's body or head
  document.body.appendChild(scriptElement);

  const initServicesWithCookies = () => {
    if (get(window, ["Cookiebot", "consent", "statistics"], false)) {
      initializeGA();
    }
  };

  const removeCookies = () => {
    handleGACookies();
  };

  window.addEventListener("CookiebotOnAccept", initServicesWithCookies);
  window.addEventListener("CookiebotOnDecline", removeCookies);

  return function cleanup() {
    window.removeEventListener("CookiebotOnAccept", initServicesWithCookies);
    window.removeEventListener("CookiebotOnDecline", removeCookies);
  };
};
