import { OPEN_NOTIFICATION, CLOSE_NOTIFICATION } from "../types";

export const initialState = {
  open: false,
  message: "",
  variant: "success",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_NOTIFICATION:
      return { open: true, variant: "success", ...action.payload };
    case CLOSE_NOTIFICATION:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
