import { get } from "lodash";
import {
  SET_AUTH_INIT,
  SET_AUTH_LOADER,
  TOGGLE_ALLOW_NEWSLETTERS_PAGE,
} from "../types";

export const initialState = {
  authLoader: false,
  authInit: false,
  allowNewslettersPage: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_LOADER:
      return {
        ...state,
        authLoader: get(action, "payload"),
      };
    case SET_AUTH_INIT:
      return {
        ...state,
        authInit: get(action, "payload"),
      };
    case TOGGLE_ALLOW_NEWSLETTERS_PAGE:
      return {
        ...state,
        allowNewslettersPage: get(action, "payload"),
      };
    default:
      return state;
  }
};

export default reducer;
