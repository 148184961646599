import ReactGA from "react-ga4";

import { get, includes, capitalize } from "lodash";

const trackGraphsPage = () =>
  gaEvent({
    category: "Resources",
    action: "See graphs [btn]",
    label: "Community graphs",
  });

const trackReportsPage = () =>
  gaEvent({
    category: "Resources",
    action: "See reports [btn]",
    label: "Research report",
  });

const trackCommunitySubscription = (newsletter) =>
  gaEvent({
    category: "Community Members",
    action: "Newsletter subscription",
    label: newsletter,
  });

const trackCommunityUnsubscription = (newsletter) =>
  gaEvent({
    category: "Community Members",
    action: "Newsletter unsubscription",
    label: newsletter,
  });

const trackSurveySubscription = (newsletter) =>
  gaEvent({
    category: "Community Members",
    action: "Email subscription",
    label: newsletter,
  });

const trackSurveyUnsubscription = (newsletter) =>
  gaEvent({
    category: "Community Members",
    action: "Email unsubscription",
    label: newsletter,
  });

const gaPageView = (page) => ReactGA.send({ hitType: "pageview", page });

export const initializeGA = () => {
  const origin = get(window, ["location", "origin"]);

  ReactGA.initialize(process.env.GATSBY_GA_TRACKING_ID, {
    debug:
      includes(origin, "localhost") ||
      includes(origin, "community-development") ||
      includes(origin, "community-staging") ||
      includes(origin, ".netlify.com") ||
      includes(origin, "127.0.0.1"),
  });

  gaPageView(get(window, ["location", "pathname"], "/"));
};

export const gaEvent = (eventProps) => ReactGA.event(eventProps);

export const trackResources = ({ uid }) => {
  if (includes(uid, "resources.reports")) {
    trackReportsPage();
  } else if (includes(uid, "resources.graphs")) {
    trackGraphsPage();
  }
};

export const trackDownloadGraphDataXls = (graph) =>
  gaEvent({
    category: "Community engagement",
    action: "Download graph (xls)",
    label: graph,
  });

export const trackDownloadGraphDataCsv = (graph) =>
  gaEvent({
    category: "Community engagement",
    action: "Download graph (csv)",
    label: graph,
  });

export const trackDownloadPulseDataCsv = (pulse) =>
  gaEvent({
    category: "Community engagement",
    action: "Download pulse (csv)",
    label: pulse,
  });

export const trackDownloadPulseDataXls = (pulse) =>
  gaEvent({
    category: "Community engagement",
    action: "Download pulse (xls)",
    label: pulse,
  });

export const trackReportClick = (reportName) =>
  gaEvent({
    category: "Resources",
    action: "Report click",
    label: reportName,
  });

export const trackReportDownload = (reportName) =>
  gaEvent({
    category: "Resources",
    action: "Download report",
    label: reportName,
  });

export const trackReportCarousel = (reportName) =>
  gaEvent({
    category: "Resources",
    action: "Carousel report click",
    label: reportName,
  });

export const trackReportNewUser = () =>
  gaEvent({
    category: "Resources",
    action: "New user",
    label: "Report sign up",
  });

export const trackJoinNowCarousel = () =>
  gaEvent({
    category: "Join Community Goals",
    action: "Join Now [btn]",
    label: "Carousel Join Now",
  });

export const trackJoinNow = () =>
  gaEvent({
    category: "Join Community Goals",
    action: "Join Now [btn]",
    label: "Why Join?",
  });

export const trackPageLoad = (path) => gaPageView(path);

export const trackNewCommunityUser = () =>
  gaEvent({
    category: "Community Members",
    action: "New user",
    label: "New sign up",
  });

export const trackNewsletterSubscription = (newsletter) => {
  if (newsletter === "community") {
    trackCommunitySubscription(newsletter);
  } else if (newsletter === "survey") {
    trackSurveySubscription(newsletter);
  }
};

export const trackNewsletterUnsubscription = (newsletter) => {
  if (newsletter === "community") {
    trackCommunityUnsubscription(newsletter);
  } else if (newsletter === "survey") {
    trackSurveyUnsubscription(newsletter);
  }
};

export const trackEmailTemplate = (userRole, template) =>
  gaEvent({
    category: "Content",
    action: `${capitalize(userRole)} Email Template`,
    label: template,
  });

export const trackBanner = (userRole, banner) =>
  gaEvent({
    category: "Content",
    action: `${capitalize(userRole)} Banner`,
    label: banner,
  });

export const trackSocialPost = (userRole, network, post) =>
  gaEvent({
    category: "Content",
    action: `${capitalize(userRole)} ${capitalize(network)} Social Post`,
    label: post,
  });

export const trackUserProfileDropdownAction = (actionId) =>
  gaEvent({
    category: "User Profile Dropdown",
    action: "Menu Item Click",
    label: actionId, // Promote Survey or Profile Settings
  });

export const handleGACookies = () => {
  // if (!get(window, ['Cookiebot', 'consent', 'statistics'], false)) {
  //   set(window, `ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`, true);
  // } else {
  //   set(window, `ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`, false);
  // }
};

export const trackHomepageNewUser = () =>
  gaEvent({
    category: "Community engagement",
    action: "Homepage Sign up button",
    label: "Sign Up",
  });

export const trackListenNow = () =>
  gaEvent({
    category: "Community engagement",
    action: "Homepage Listen Now button",
    label: "Listen Now",
  });

export const trackMenuNewUser = () =>
  gaEvent({
    category: "Community engagement",
    action: "Menu sign up button",
    label: "Sign Up",
  });

export const trackYesIam = () =>
  gaEvent({
    category: "Community growth",
    action: "Homepage Yes I am",
    label: "Sign Up",
  });

export const trackNewsletter = () =>
  gaEvent({
    category: "Community growth",
    action: "Homepage Newsletter",
    label: "Sign Up",
  });

export const trackMenuSignUp = () =>
  gaEvent({
    category: "Community growth",
    action: "Menu Sign Up",
    label: "Sign Up",
  });

export const trackFooterNewsletter = () =>
  gaEvent({
    category: "Community growth",
    action: "Footer Newsletter",
    label: "Sign Up",
  });

export const trackCommunityNewUser = (label) =>
  gaEvent({
    category: "Community growth",
    action: "Community Page sign up button",
    label: label,
  });

export const trackCommunityWhoElse = () =>
  gaEvent({
    category: "Community growth",
    action: "Community Page Who else button ",
    label: "Who else",
  });

export const trackCoursesCertifications = (title) =>
  gaEvent({
    category: "Community growth",
    action: "Courses & Certifications",
    label: title,
  });

export const trackWorkshopsWebinars = () =>
  gaEvent({
    category: "Community growth",
    action: "Workshops & Webinars",
    label: "Have a look",
  });

export const trackKnowledgePodcast = (title) =>
  gaEvent({
    category: "Community growth",
    action: "Knowledge Podcast",
    label: "Start now",
  });

export const trackReferrerApply = (title) =>
  gaEvent({
    category: "Community growth",
    action: "Referrer apply now top button",
    label: "Apply now",
  });

export const trackReferrerApplyForm = (title) =>
  gaEvent({
    category: "Community growth",
    action: "Referrer apply button in form",
    label: "Apply now",
  });

export const trackNewPartner = (title) =>
  gaEvent({
    category: "Community growth",
    action: "Become our media partner",
    label: "Contacts us",
  });

export const trackAffiliateTopBtn = (title) =>
  gaEvent({
    category: "Community growth",
    action: "Affiliate top button",
    label: "Contacts us",
  });

export const trackAffiliateBottomBtn = (title) =>
  gaEvent({
    category: "Community growth",
    action: "Affiliate bottom button",
    label: "Contacts us",
  });

export const trackGoal = (option) =>
  gaEvent({
    category: "Sign up",
    action: "Select the check box",
    label: option,
  });
