import { fetchAuthSession } from "aws-amplify/auth";
import { get } from "lodash";

const getJwt = async () => {
  try {
    const { tokens } = await fetchAuthSession();

    return tokens?.idToken?.toString();
  } catch (error) {
    return get(
      error,
      "message",
      error ? error.toString() : "catched_undefined"
    );
  }
};

export default getJwt;
