import { combineReducers } from "redux";
import { i18nReducer } from "react-redux-i18n";
import notification from "./reducers/notification";
import user from "./reducers/user";
import base from "./reducers/base";
import survey from "./reducers/survey";

export default combineReducers({
  i18n: i18nReducer,
  notification,
  user,
  base,
  survey,
});
