import { normalizeUser } from "../../helpers/normalizeUrser";
import getJwt from "../../helpers/getJwt";
import { signOut as authSignout } from "aws-amplify/auth";
import { get } from "aws-amplify/api";
import { SET_SSO_ERROR, SET_USER } from "../types";
import { concat } from "lodash";
import { initialState } from "../reducers/user";

export const getUserData = (user) => async (dispatch) => {
  try {
    const normalizedUser = normalizeUser(user);
    const jwt = await getJwt();
    const headers = { Authorization: `Bearer ${jwt}` };

    const responses = await Promise.all([
      get({
        apiName: "users",
        path: `/users/${normalizedUser.userId}`,
        options: {
          headers,
        },
      }).response,
      get({
        apiName: "community",
        path: `/users/${normalizedUser.userId}/points`,
        options: {
          headers,
        },
      }).response,
      get({
        apiName: "community",
        path: `/users/${normalizedUser.userId}/surveyprofile`,
        options: {
          headers,
        },
      }).response,
      get({
        apiName: "users",
        path: `/users/${normalizedUser.userId}/communications`,
        options: {
          headers,
        },
      }).response,
    ]);

    const [userDoc, points, surveyprofile, newsletters] = await Promise.all(
      responses.map((r) => r.body.json())
    );

    const { affiliateId, category, organisation, profile } = userDoc;

    dispatch(
      setUser({
        ...normalizedUser,
        roles: concat(normalizedUser.roles, [category]),
        affiliateId,
        organisation,
        profile,
        points,
        surveyprofile,
        newsletters,
      })
    );
  } catch (e) {
    throw e;
  }
};

export const signOut = (user) => async (dispatch) => {
  await authSignout();

  if (user.scope === "aws.cognito.signin.user.admin") {
    dispatch(setUser(initialState));
  }
};

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const setSsoError = (error) => ({
  type: SET_SSO_ERROR,
  error,
});
