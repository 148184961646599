import { get } from "lodash";
import {
  SET_USER,
  SET_USER_FIRST_NAME,
  SET_USER_LAST_NAME,
  SET_USER_COUNTRY_CODE,
  SET_SSO_ERROR,
} from "../types";

export const initialState = {
  userId: "",
  roles: [],
  email: "",
  profile: {
    firstname: "",
    lastname: "",
    countryCode: "",
  },
  points: {
    all: 0,
    redemption: 0,
  },
  ssoError: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...get(action, "payload", {}),
      };
    case SET_USER_FIRST_NAME:
      return {
        ...state,
        profile: {
          ...get(state, "profile", {}),
          firstname: get(action, "payload"),
        },
      };
    case SET_USER_LAST_NAME:
      return {
        ...state,
        profile: {
          ...get(state, "profile", {}),
          lastname: get(action, "payload"),
        },
      };
    case SET_USER_COUNTRY_CODE:
      return {
        ...state,
        profile: {
          ...get(state, "profile", {}),
          countryCode: get(action, "payload"),
        },
      };
    case SET_SSO_ERROR:
      return {
        ...state,
        ssoError: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
