import { get } from "lodash";

export const normalizeUser = (tokens) => {
  const userId = get(tokens, ["idToken", "payload", "sub"], "");
  const email = get(tokens, ["idToken", "payload", "email"], "");
  const groups = get(tokens, ["idToken", "payload", "cognito:groups"], []);
  const scope = get(tokens, ["accessToken", "payload", "scope"]);

  return {
    userId,
    email,
    roles: groups,
    scope,
  };
};
